import React from "react"
import AgenciesMarquee from "../agencies-marquee"

const PerformanceSection = () => {
  return (
    <section className="py-5">
      <h1
        className="is-size-1-desktop is-size-3-mobile has-text-weight-semibold has-text-centered pt-5"
        style={{ textAlign: "center", lineHeight: "1.2" }}
      >
        <span className="text-dark-blue">Drive Performance with </span>{" "}
        <br className="is-hidden-desktop" />
        <span className="text-line text-blue"> Best in Class AI PMPs</span>
      </h1>
      <section>
        <AgenciesMarquee title="" />
      </section>
    </section>
  )
}

export default PerformanceSection
